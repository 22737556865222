import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "bootstrap/dist/css/bootstrap.min.css"
import { graphql, Link } from "gatsby"
import { map } from "lodash"
import { Card } from "react-bootstrap"
import useCart from "../hooks/useCart"
import { StaticImage } from "gatsby-plugin-image"

const ProductsPage = ({ data }) => {
  const { nodes } = data.allProducts
  const { getPrice } = useCart()

  const getItemPrice = (variants, forceSinglePrice = false) => {
    if(variants.length == 1 || forceSinglePrice) {
      return getPrice(variants[variants.length - 1].cost)
    }
    return getPrice(variants[0].cost) + ' - $' + getPrice(variants[variants.length - 1].cost)
  }

  const getItemImage = label => {
    switch (label?.toLowerCase()) {
      case "ashur":
        return (
          <StaticImage
            src={"../images/ashur_pendant/insta-ashur-1.png"}
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="ashur pendant image"
            className="img-fluid rounded"
          />
        )
      case "lamassu":
        return (
          <StaticImage
            src={"../images/lamassu_pendant/image_0_3.jpg"}
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="lamassu pendant image"
            className="img-fluid rounded"
          />
        )
      case "assyrian flag":
        return (
          <StaticImage
            src={"../images/assyrian_flag_pendant/image_0_0.jpg"}
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="assyrian flag pendant image"
            className="img-fluid rounded"
          />
        )
      case "rosette":
        return (
          <StaticImage
            src={"../images/rosette_pendant/image_0_0.jpg"}
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="rosette pendant image"
            className="img-fluid rounded"
          />
        )
      case "tree of life":
        return (
          <StaticImage
            src={"../images/tofl_pendant/image_0_0.jpg"}
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="tree of life pendant image"
            className="img-fluid rounded"
          />
        )
      case "ashur x lapis":
        return (
          <StaticImage
            src="../images/ashur_lapis/image_0_0.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Ashur x Lapis Lazuli ring"
            className="img-fluid rounded"
          />
        )
      default:
        return (
          <StaticImage
            src={"../images/ashur_pendant/insta-ashur-1.png"}
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="ashur pendant image"
            className="img-fluid rounded"
          />
        )
    }
  }

  return (
    <Layout>
      <Seo title="Products" />
      <section className="py-5 text-center container text-white">
        <div className="row justify-content-center justify-content-md-start pb-5 mb-5 pb-md-0 mb-md-0">
          {map(nodes, (product, index) => (
            <div className="col-9 col-md-3 my-3 my-md-2" key={index}>
              <Link
                className="card bg-transparent border-dark shadow text-white text-decoration-none text-start"
                to={`/${product.slug}`}
              >
                {getItemImage(product?.label?.toLowerCase())}
                <Card.Body>
                  <p className="h5 mb-0 minimal-title gold-text">
                    {product.label}
                  </p>
                </Card.Body>
                <Card.Footer>
                  <p className="mb-0 minimal-title">
                    ${getItemPrice(product.variants, product?.isARingProduct)}
                  </p>
                </Card.Footer>
              </Link>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export default ProductsPage

export const query = graphql`
  query {
    allProducts {
      nodes {
        title
        label
        slug
        images
        isARingProduct
        variants {
          name
          cost
          features
          label
          sku
        }
      }
    }
  }
`
